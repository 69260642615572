<template>
  <v-card class="rounded-card " :elevation="elevation">
    <slot></slot>
  </v-card>
</template>

<script>
export default {
  props: {
    elevation: {
      type: [Number, String],
      default: 3,
    },
  },
};
</script>

<style scoped>
.rounded-card {
  border-radius: 20px;
  max-width: "100%";
}
.mi-clase-con-sombra {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12) !important;
}
</style>
