import { render, staticRenderFns } from "./CommonExpansionPanel.vue?vue&type=template&id=3de22a1a&scoped=true&"
import script from "./CommonExpansionPanel.vue?vue&type=script&lang=js&"
export * from "./CommonExpansionPanel.vue?vue&type=script&lang=js&"
import style0 from "./CommonExpansionPanel.vue?vue&type=style&index=0&id=3de22a1a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3de22a1a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
installComponents(component, {VExpansionPanel,VExpansionPanelHeader,VExpansionPanels})
