<template>
  <div>
    <v-card class="mx-auto" color="white" width="100%"
      style="border-radius: 20px; box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12) !important;" elevation="0">

      <!-- Versión Desktop -->
      <template v-if="$vuetify.breakpoint.mdAndUp">
        <v-card-title @click="showAll" v-if="!openAll" class="desktop-title">
          <v-img :src="notesImg" max-width="29" class="docs-icon theme--light" alt=""></v-img>
          <br />
        </v-card-title>

        <v-card-title v-if="openAll" class="desktop-title-open">
          <v-row>
            <v-col cols="10" @click="showAll">
              <v-row>
                <v-col cols="2">
                  <v-img :src="notesImg" class="docs-icon theme--light" max-width="100" alt=""></v-img>
                </v-col>
                <v-col cols="10">
                  <span class="docs-title">Notas</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="2" v-if="canEdit" @click="OpenDialogCreateDocument = true" class="d-flex justify-end align-center">
              <v-img :src="AddIcon" class="add-icon theme--light" max-width="70" alt=""></v-img>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text v-if="!openAll" class="text-center">
          <span class="docs-text-title">Notas</span>
        </v-card-text>

        <v-card-text v-if="openAll">
          <div v-if="notas.length > 0" class="docs-list-container">
            <v-row v-for="(nota, idx) in notas.slice(0, 3)" :key="idx">
              <v-col cols="12" class="custom-list">
                {{ nota.formatData }}
                <span style="color:#0070C0; cursor: pointer;" @click="OpenDialogShowNotes = true">
                  Ver
                </span>
              </v-col>
            </v-row>
          </div>
          <div v-if="notas.length > 0" class="text-center">
            <v-btn elevation="0" color="white" @click="OpenDialogShowNotes = true">
              <span style="color:#0070C0">ver mas</span>
            </v-btn>
          </div>
          <div v-else class="docs-empty-container text-center">
            <v-row>
              <v-col cols="12">
                <h3>No hay nada por el momento</h3>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </template>

      <!-- Versión Mobile -->
      <template v-else>
        <v-card-title @click="showAll" v-if="!openAll" class="mobile-title">
          <v-img :src="notesImg" max-width="29" alt=""></v-img>
          <br />
        </v-card-title>

        <v-card-title v-if="!openAll" class="mobile-title">
          <span class="docs-title">Notas</span>
        </v-card-title>

        <v-card-title v-if="openAll" class="mobile-title-open">
          <v-row>
            <v-col cols="10" @click="showAll">
              <v-img :src="notesImg" class="docs-icon theme--light" max-width="30" alt="" />
            </v-col>
            <v-col cols="10" class="text-center">
              <span class="docs-title">Notas</span>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text v-if="openAll" class="text-center">
          <div v-if="notas.length > 0" class="docs-list-container">
            <v-row v-for="(nota, idx) in notas.slice(0, 3)" :key="idx">
              <v-col cols="12" class="custom-list">
                {{ nota.formatData }}
                <span style="color:#0070C0; cursor: pointer;" @click="OpenDialogShowNotes = true">
                  Ver
                </span>
              </v-col>
            </v-row>
          </div>
          <div v-if="notas.length > 0" class="text-center">
            <v-btn elevation="0" color="white" @click="OpenDialogShowNotes = true">
              <span style="color:#0070C0">ver más</span>
            </v-btn>
          </div>
          <div v-else class="docs-empty-container text-center">
            <v-row>
              <v-col cols="12">
                <h3>No hay nada por el momento</h3>
              </v-col>
            </v-row>
            <v-row class="mt-4 text-center" v-if="canEdit">
              <v-col cols="12">
                <v-btn outlined color="#00a7e4" @click="OpenDialogCreateDocument = true" rounded>Agregar Nota</v-btn>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </template>
    </v-card>

    <create-notes-dialog :OpenDialogCreateDocument="OpenDialogCreateDocument"
      :routesConfigNoteCard="routesConfigNoteCard" @closeModal="OpenDialogCreateDocument = false" @success="getData">
    </create-notes-dialog>
    <show-notes-dialog :OpenDialogShowNotes="OpenDialogShowNotes" :notas="notas"
      @closeModal="OpenDialogShowNotes = false" @openCreateModal="OpenDialogCreateDocument = true">
    </show-notes-dialog>
  </div>
</template>

<script>
import CreateNotesDialog from "../dialogs/CreateNotesDialog.vue";
import ShowNotesDialog from "../dialogs/ShowNotesDialog.vue";
import notes from "@/assets/notes.svg";
import addIcon from "@/assets/addIcon.svg";

export default {
  components: {
    CreateNotesDialog,
    ShowNotesDialog,
  },
  props: {
    notas: Array,
    routesConfigNoteCard: Object,
    openAll: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      showNotes: false,
      OpenDialogCreateDocument: false,
      OpenDialogShowNotes: false,
      notesImg: notes,
      AddIcon: addIcon,
    };
  },

  watch: {
    openAll(val) {
      this.showNotes = val;
    },
  },

  methods: {
    getData() {
      this.OpenDialogCreateDocument = false;
      this.$emit("getData");
    },
    showAll() {
      this.$emit("showAll", !this.showNotes);
    },
  },
};
</script>

<style scoped>
.docs-card {
  border-radius: 20px;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12) !important;
}

.desktop-title, .mobile-title {
  justify-content: center;
  cursor: pointer;
  min-height: 60px;
}

.docs-icon {
  width: 40px;
  height: auto;
}

.add-icon {
  width: 40px;
  height: auto;
}

.docs-title,
.docs-text-title {
  font-weight: bold;
  font-size: 1.25rem;
  letter-spacing: 0.0125em;
  line-height: 2rem;
  word-break: break-all;
  color: rgba(0, 0, 0, 0.87);
  margin-left: 8px;
}

.docs-list-container {
  height: 180px !important;
}

.docs-empty-container {
  height: 216px !important;
}
</style>
