<template>
    <div>
      <v-card
        class="mx-auto docs-card"
        color="white"
        width="100%"
        elevation="0"
      >
        <!-- Versión Desktop -->
        <template v-if="$vuetify.breakpoint.mdAndUp">
          <!-- Card title si NO está abierto -->
          <v-card-title
            v-if="!openAll"
            class="desktop-title"
            @click="showAll"
          >
            <v-img
              :src="docsImg"
              max-width="29"
              class="docs-icon theme--light"
              alt="Docs Icon"
            />
          </v-card-title>

          <!-- Card title si SÍ está abierto -->
          <v-card-title
            v-else
            class="desktop-title-open"
          >
            <v-row no-gutters>
              <!-- Col con el icono y el título “Docs” -->
              <v-col
                cols="10"
                class="d-flex align-center"
                @click="showAll"
              >
                <v-row align="center" no-gutters>
                  <v-col cols="auto">
                    <v-img
                      :src="docsImg"
                      class="docs-icon theme--light"
                      alt="Docs Icon"
                    />
                  </v-col>
                  <v-col cols="auto">
                    <!-- Texto “Docs” en negrita -->
                    <span class="docs-title">
                      Docs
                    </span>
                  </v-col>
                </v-row>
              </v-col>

              <!-- Ícono “Agregar documento” con tamaño similar (40px) -->
              <v-col
                v-if="canEdit"
                cols="2"
                class="d-flex justify-end align-center"
                @click="OpenDialogCreateDocument = true"
              >
                <v-img
                  :src="AddIcon"
                  class="add-icon theme--light"
                  alt="Agregar Icon"
                />
              </v-col>
            </v-row>
          </v-card-title>

          <!-- Contenido si NO está abierto -->
          <v-card-text v-if="!openAll" class="text-center">
            <span class="docs-text-title">
              Docs
            </span>
          </v-card-text>

          <!-- Contenido si SÍ está abierto -->
          <v-card-text v-else>
            <div v-if="docs.length > 0" class="docs-list-container">
              <v-row
                v-for="(doc, idx) in docs.slice(0, 3)"
                :key="idx"
              >
                <v-col cols="8" class="d-flex align-center">
                  <h4>{{ doc.nombre }}</h4>
                </v-col>
                <v-col cols="4" class="text-end">
                  <v-icon
                    left
                    color="#00a7e4"
                    size="26"
                    style="cursor: pointer;"
                    @click="OpenDialogShowDocs = true"
                  >
                    mdi-eye-outline
                  </v-icon>
                </v-col>
              </v-row>
            </div>
            <div v-if="docs.length > 0" class="text-center">
              <v-btn
                elevation="0"
                color="white"
                @click="OpenDialogShowDocs = true"
              >
                <span style="color:#0070C0">ver mas</span>
              </v-btn>
            </div>
            <div
              v-else
              class="docs-empty-container text-center"
            >
              <v-row>
                <v-col cols="12">
                  <h3>No hay nada por el momento1</h3>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </template>

        <!-- Versión Mobile -->
        <template v-else>
          <!-- Card title si NO está abierto -->
          <v-card-title
            v-if="!openAll"
            class="mobile-title"
            @click="showAll"
          >
            <v-img
              :src="docsImg"
              max-width="29"
              alt="Docs Icon"
            />
          </v-card-title>

          <!-- Card title si SÍ está abierto -->
          <v-card-title
            v-else
            class="mobile-title-open"
          >
            <v-row>
              <v-col cols="10" @click="showAll">
                <v-row>
                  <v-col cols="2">
                    <v-img
                      :src="docsImg"
                      max-width="25"
                      alt="Docs Icon"
                    />
                  </v-col>
                  <v-col cols="10">
                    <span>Docs</span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-title>

          <!-- Contenido si NO está abierto -->
          <v-card-text v-if="!openAll" class="text-center">
            <span class="docs-text-title">
              Docs
            </span>
          </v-card-text>

          <!-- Contenido si SÍ está abierto -->
          <v-card-text v-else>
            <div v-if="docs.length > 0" class="docs-list-container">
              <v-row
                v-for="(doc, idx) in docs.slice(0, 3)"
                :key="idx"
              >
                <v-col cols="8" class="d-flex align-center">
                  <h4>{{ doc.nombre }}</h4>
                </v-col>
                <v-col cols="4" class="text-end">
                  <v-icon
                    left
                    color="#00a7e4"
                    size="26"
                    style="cursor: pointer;"
                    @click="OpenDialogShowDocs = true"
                  >
                    mdi-eye-outline
                  </v-icon>
                </v-col>
              </v-row>
            </div>

            <div v-if="docs.length > 0" class="text-center">
              <v-btn
                elevation="0"
                color="white"
                @click="OpenDialogShowDocs = true"
              >
                <span style="color:#0070C0">ver más</span>
              </v-btn>
            </div>

            <div v-else class="docs-empty-container text-center">
              <v-row>
                <v-col cols="12">
                  <h3>No hay nada por el momento</h3>
                </v-col>
              </v-row>
              <v-row class="mt-4 text-center">
                <v-col cols="12">
                  <v-btn
                    outlined
                    color="#00a7e4"
                    @click="OpenDialogCreateDocument = true"
                    class="ma-3"
                    rounded
                  >
                    Agregar Documento
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </template>
      </v-card>

      <!-- Diálogos -->
      <create-document-dialog
        :OpenDialogCreateDocument="OpenDialogCreateDocument"
        :routesConfigDocsCard="routesConfigDocsCard"
        @closeModal="OpenDialogCreateDocument = false"
        @success="getData"
      />
      <show-documents-dialog
        :docs="docs"
        :OpenDialogShowDocs="OpenDialogShowDocs"
        @closeModal="OpenDialogShowDocs = false"
        @openCreateModal="OpenDialogCreateDocument = true"
      />
    </div>
  </template>

  <script>
  import CreateDocumentDialog from '../dialogs/CreateDocumentDialog.vue';
  import ShowDocumentsDialog from '../dialogs/ShowDocumentsDialog.vue';
  import docs from "@/assets/docs.svg";
  import addIcon from "@/assets/addIcon.svg";

  export default {
    components: {
      CreateDocumentDialog,
      ShowDocumentsDialog
    },
    data() {
      return {
        showStatus: false,
        docsArray: [],
        OpenDialogCreateDocument: false,
        OpenDialogShowDocs: false,
        docsImg: docs,
        AddIcon: addIcon,
      };
    },
    props: {
      docs: {
        type: Array,
        default: () => []
      },
      routesConfigDocsCard: {
        type: Object,
        default: () => {}
      },
      openAll: {
        type: Boolean,
        default: false
      },
      canEdit: {
        type: Boolean,
        default: true
      }
    },
    watch: {
      openAll(val) {
        this.showStatus = val;
      }
    },
    methods: {
      getData() {
        this.OpenDialogCreateDocument = false;
        this.$emit("getData");
      },
      showAll() {
        this.$emit('showAll', !this.showStatus);
      }
    }
  };
  </script>

  <style scoped>
  .docs-card {
    border-radius: 20px;
    box-shadow:
      0px 3px 3px -2px rgba(0, 0, 0, 0.2),
      0px 3px 4px 0px rgba(0, 0, 0, 0.14),
      0px 1px 8px 0px rgba(0, 0, 0, 0.12) !important;
  }

  /* Título en desktop (cerrado y abierto) */
  .desktop-title,
  .desktop-title-open {
    justify-content: center;
    cursor: pointer;
    min-height: 60px;
  }

  /* Ícono de Docs */
  .docs-icon {
    width: 40px;
    height: auto;
  }

  /* Ícono “Agregar” mismo tamaño */
  .add-icon {
    width: 40px;
    height: auto;
  }

  /* Texto “Docs” en negrita */
  .docs-title,
  .docs-text-title {
    font-weight: bold;
    font-size: 1.25rem;
    letter-spacing: 0.0125em;
    line-height: 2rem;
    word-break: break-all;
    color: rgba(0, 0, 0, 0.87);
    margin-left: 8px; /* Espacio entre ícono y texto */
  }

  /* Contenedor de la lista de documentos */
  .docs-list-container {
    height: 180px !important;
  }

  /* Contenedor vacío cuando no hay documentos */
  .docs-empty-container {
    height: 216px !important;
  }
  </style>
