<template>
  <v-card style="box-shadow: none !important; position: sticky; top: 0; z-index:100">
    <!-- Crea una tarjeta con sombra desactivada, posición fija en la parte superior y z-index 100 -->
    <v-navigation-drawer
      v-model="drawer"
      app
      :color="'#FFF'"
      :dark="false"
      :mini-variant="mini"
      :mini-variant-width="80"
      @click.stop="console.log('Clic en drawer detenido')"
      > <!-- Detiene la propagación del evento clic -->
      <v-list-item class="mt-4"><!-- Crea un ítem de lista -->
        <!-- Muestra el logo de Interify en tamaño grande o pequeño según el valor de "mini" -->
        <v-img
          class="mx-5 responsive-img mb-2"
          contain
          :max-width="mini ? '40' : '150'"
          :src="mini ? InterifyXS : globalConfig.logoUrl"
          @click.stop="!mini ? irInicio() : (mini = !mini)"
          style="cursor:pointer"
        ></v-img>

        <v-btn class="ml-auto" icon @click="mini = !mini">
          <v-icon color="#ABB1B3">
            {{ !mini ? "mdi-chevron-double-left" : "mdi-chevron-double-right" }}
          </v-icon>
        </v-btn>
      </v-list-item>
      <v-divider></v-divider>

      <div>
        <v-list dense v-for="(list, idx) in filteredListaDesplegable" :key="idx">
          <!-- Recorre la lista filtrada "filteredListaDesplegable" y crea un listado para cada elemento -->
          <v-list-group
            v-for="item in list.items"
            :key="item.title"
            v-model="item.active"
            no-action
            :class="{ 'parent-active': isParentActive(item) }"
            style="padding-top: 5%;"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <div class="d-flex align-center">
                  <v-icon size="26" :color="isParentActive(item) ? '#0070C0' : '#666'" @click.stop="mini = !mini">
                    {{ item.action }}
                  </v-icon>
                  <v-list-item-title
                    class="ml-3"
                    :class="{
                      'text-center': mini,
                      'parent-text-active': isParentActive(item)
                    }"
                    v-if="!mini"
                    v-text="item.title"
                  ></v-list-item-title>
                </div>
              </v-list-item-content>
            </template>
            <template v-for="child in item.items">
              <v-list-item
                :key="child.title"
                @click.stop="goToRoute(child.route)"
                :class="{ 'child-active': isActive(child.route) }"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="child.title"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-group>
        </v-list>
      </div>

      <v-divider></v-divider>
      <v-list dense style="cursor: pointer">
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title @click="saveLoginLog">Salir</v-list-item-title><!-- Llama al método "saveLoginLog" al hacer clic -->
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
const setListaDesplegable = require("../helpers/diccionarios/listaDesplegable.js");
import mainData from "../helpers/data/data-main-template.json";
import loginData from "../helpers/data/data-login-template.json";
import LogoInter from "@/assets/interify.svg";
import interifyXS from "@/assets/interify_xs.svg";
import { postLog, getAreaNegocio, getRoles } from "@/services/log/log.service.js";
import { mainAxios } from '@/mainAxios.js';

export default {
  data() {
    return {
      rol: JSON.parse(localStorage.getItem("roles"))[0]?.rol || "",
      // Obtiene el rol del usuario desde el localStorage.
      // Si no encuentra un rol válido, utiliza una cadena vacía como valor por defecto.
      emailCoru: localStorage.getItem("agenteMail"),
      listaDesplegable: null,
      mainTemplate: mainData,
      loginTemplate: loginData,
      logoInter: LogoInter,
      // Iniciamos cerrado en pantallas chicas y abierto en pantallas grandes
      drawer: this.$vuetify?.breakpoint?.smAndDown ? false : true,
      mini: false,
      InterifyXS: interifyXS,
      globalConfig: JSON.parse(localStorage.getItem("globalConfig")) ?? {},
      areaNegocio: [],
      roles: [],
    };
  },
  props: {
    mobileMenu: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    // Filtrar el menú para ocultar "Perfil Corporativo" si el rol es Admin Corp

    filteredListaDesplegable() {
      // Filtra la lista desplegable según el rol del usuario.
      if (this.rol === "ADMINCORP") {
        return this.listaDesplegable.map((list) => ({
          ...list,
          items: list.items.filter(
            (item) => item.title !== "Perfil Corporativo"
          ),
        }));
      }
      return this.listaDesplegable;
      // Retorna la lista sin modificaciones si el rol no es ADMINCORP
    },
  },
  watch: {
    mobileMenu() {
      this.mini = false;
      this.drawer = !this.drawer;
    },
    drawer(val) {
      console.log("Estado del drawer cambiado a:", val);
    },
    // Cerramos el drawer en mobile al cambiar de ruta
    $route() {
      this.updateGroupActiveStates();
      if (this.$vuetify?.breakpoint?.smAndDown) {
        this.drawer = false;
      }
    },
  },
  mounted() {
    this.listaDesplegable = setListaDesplegable(this.rol);
    this.updateGroupActiveStates();
    this.getRolesList();
    this.getAreaNegocio();
  },
  methods: {
    isActive(route) {
      return this.$route.path === route;
    },
    isParentActive(item) {
      return item.items.some(child => this.isActive(child.route));
    },
    updateGroupActiveStates() {
      if (!this.listaDesplegable) return;
      this.listaDesplegable.forEach(list => {
        list.items.forEach(item => {
          item.active = item.items.some(child => this.isActive(child.route));
        });
      });
    },
    goToRoute(route) {
      this.$router.push(route);
      if (this.$vuetify?.breakpoint?.smAndDown) {
        this.drawer = false;
      }
    },

    logout() {
      localStorage.clear();
      this.$router.push("/");
    },

    async getRolesList() {
      this.roles = await getRoles();
    },

    async getAreaNegocio() {
      this.areaNegocio = await getAreaNegocio();
    },

    async saveLoginLog() {
      const body = {
        agente_id: localStorage.id,
        actividad_id: 13,
        servicio_embebido: "",
        area_negocio_id: this.getActualAreaNegocio(),
      };
      await postLog(body);
      this.logout();
    },
    getActualAreaNegocio() {
      const currentRol = this.roles.find((e) => e.rol == this.rol);
      const currentAreaNegocio = this.areaNegocio.find(
        (e) => e.id == currentRol.area_negocio
      );
      return currentAreaNegocio ? currentAreaNegocio.id : "";
    },


    obtenerNotificaciones() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };

      mainAxios
        .get(`/v1/agente/notificaciones/${localStorage.agenteUuid}`, config)
        .then((response) => {
          for (let index = 0; index < 5; index++) {
            this.notificaciones.push({
              titulo: response.data[index].titulo,
              creadoPor: response.data[index].creadoPor,
              subtitulo: response.data[index].subtitulo,
              fecha: response.data[index].fecha,
              id: response.data[index].id,
              contenido: response.data[index].contenido,
            });
          }
        });
    },

    verNotificacion(notificacion) {
      this.dialogNotificacion = true;
      this.detalleNotificacion = notificacion;
    },

    verNotificacionesCompletas() {
      this.$router.push(`/notificaciones/${localStorage.agenteUuid}`);
    },

    irInicio() {
      switch (this.rol) {
        case "ADMINCORP":
          this.$router.push("/perfil/alianza");
          break;
        case "AGENTCC":
          this.$router.push("/leads");
          break;
        case "PROSPECTO":
          this.$router.push("/inicio-prospectos");
          break;
        case "AGENTCORP":
          this.$router.push("/perfil/alianza");
          break;
        case "OPERADORINBOUND":
        case "OPERACIONESINBOUND":
        case "MESACONTROLINBOUND":
          this.$router.push("/main-hub/1");
          break;
        default:
          this.$router.push("/inicio");
          break;
      }
    },
  },
};
</script>

<style>
.v-carousel__controls {
  bottom: 12px;
}

.v-list-item__title {
  white-space: normal !important;
}

/* Parent menu items */
.parent-active {
  background-color: transparent !important;
}

.parent-text-active {
  color: #00B0F0 !important; /* Color azul claro para texto activo */
}

/* Child menu items */
.child-active {
  background-color: #E8E8E8 !important; /* Color gris exacto */
}

.child-active .v-list-item__title {
  color: #000000 !important;
}

/* Icons always blue */
.v-list-group__header .v-icon {
  color: #0070C0 !important; /* Azul para íconos */
}

/* Hover states */
.v-list-item:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.v-list-group__header:hover {
  background-color: transparent !important;
}
</style>
