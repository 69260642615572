<template>
  <v-expansion-panels elevation="0" class="mi-clase-con-sombra"
    v-model="panel"
    :readonly="readonly">
    <v-expansion-panel>
      <v-expansion-panel-header style="padding: 35px;">
        <h3 style="color:#42352E;">
          <slot name="title-panel">
          </slot>
        </h3>
      </v-expansion-panel-header>
      <slot>
      </slot>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  props: {
    currentPanel: {
      type: Number,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      panel: this.currentPanel,
    };
  },
  watch: {
    panel(v) {
      if (v === null || v === undefined) {
        this.$emit("change", false); // Emitimos false si el panel está colapsado
      } else {
        this.$emit("change", true); // Emitimos true si el panel está expandido
      }
    },
    currentPanel(v) {
      this.panel = v;
    },
  },
};
</script>

<style scoped>
.v-expansion-panel {
  border-radius: 20px;
}
.v-expansion-panels {
  z-index: auto !important;
}
.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile) > .v-expansion-panel--active {
  border-radius: 20px !important;
}
.mi-clase-con-sombra {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12) !important;
  border-radius: 20px;
}
</style>
